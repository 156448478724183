import {Pagination, PaginationItem, PaginationLink} from "reactstrap";
import ScheduleInfo from "./schedule-info";
import { useEffect, useState } from "react";
import { POST_SHIFT_SEARCH } from "../../../../../helpers/url_helper";
import { post } from "../../../../../helpers/api_helper";
import ReactPaginate from "react-paginate";

export default function ListSchedule({ perPage, searchStatus }){
    const [shifts, setShifts] = useState([]);
    const [currentPerPage, setCurrentPerPage] = useState(perPage);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        post(POST_SHIFT_SEARCH, {
            "status": searchStatus,
            "page": currentPage,
            "limit": currentPerPage,
            "orderBy": ""
        }).then(response => {
            if (response.status === 200) {
                setShifts(response.body.content);
                setTotalPages(response.body.totalPages);
            }
        })
    }, [currentPage, currentPerPage, searchStatus]);

    useEffect(() => {
        setCurrentPerPage(perPage);
        setCurrentPage(1);
        setTotalPages(1);
    }, [perPage]);

    const handlePageClick = (page) => {
        setCurrentPage(page + 1);
    }

    return (
        <div className="table-info panel panel-default">
            <table className="table table-striped table-bordered table-responsive mb-3">
                <thead className="border">
                <tr>
                    <th style={{width: "40%"}}>Mã ca </th>
                    <th>Trạng thái</th>
                </tr>
                </thead>
                <tbody>
                {
                    shifts && shifts.map((item) => (
                        <ScheduleInfo
                            key={item.shiftCode}
                            id={item.shiftCode}
                            name={item.shiftName}
                            startTime={item.startDate}
                            endTime={item.endDate}
                            status={item.status}
                            colorCode={item.colorCode}
                        />
                    ))
                }
                </tbody>
            </table>
            <ReactPaginate
                breakLabel="..."
                nextLabel="Sau"
                onPageChange={({ selected }) => handlePageClick(selected)}
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                pageCount={totalPages}
                forcePage={currentPage - 1}
                previousLabel="Trước"
                renderOnZeroPageCount={null}
                className="pagination"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                activeClassName="active"
            />
        </div>
    )
}