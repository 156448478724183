import { Button, Col, Input, Label, Row } from "reactstrap";
import React, { useEffect, useState } from "react";
import toastr from "toastr";
import "./index.css"
import { Link, useLocation, useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { POST_SHIFT_DETAIL, POST_SHIFT_UPDATE } from "../../../../helpers/url_helper";
import { post } from "../../../../helpers/api_helper";
import { showToastErrorDefault, showToastSuccessDefault } from "../../../../store/actions";
export default function ScheduleDetails() {
    const { code } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const [shiftDetail, setShiftDetail] = useState({
        code: code,
        shiftName: "",
        startDate: "",
        endDate: "",
        colorCode: "",
        status: ""
    });
    const [invalidForm, setInvalidForm] = useState({
        shiftName: false,
        startDate: false,
        endDate: false
    });

    useEffect(() => {
        post(POST_SHIFT_DETAIL, { code }).then(response => {
            if (response.status === 200) {
                const data = response.body;
                setShiftDetail({
                    ...shiftDetail,
                    shiftName: data.shiftName ?? "",
                    startDate: data.startDate ?? "",
                    endDate: data.endDate ?? "",
                    colorCode: data.colorCode ?? "#000000",
                    status: data.status
                });
            }
        })
    }, []);

    const handleSubmit = () => {
        const validateForm = {
            shiftName: shiftDetail.shiftName.trim() === "",
            startDate: shiftDetail.startDate.trim() === "",
            endDate: shiftDetail.endDate.trim() === ""
        }
        setInvalidForm(validateForm);

        if (Object.values(validateForm).every(value => value === false)) {
            post(POST_SHIFT_UPDATE, shiftDetail).then(response => {
                if (response.status === 200) {
                    dispatch(showToastSuccessDefault("Sửa ca làm việc thành công!"));
                    history.replace("/schedule/list/work");
                }
            }).catch(error => {
                if (error.response.data.status === 500) {
                    dispatch(showToastErrorDefault("Lỗi hệ thống"));
                } else {
                    dispatch(showToastErrorDefault(error.response.data.message));
                }
            });
        }
    }

    const handleChangeInput = (e) => {
        setShiftDetail({
            ...shiftDetail,
            [e.target.name]: e.target.value
        });
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Row className="d-flex flex-row justify-content-between align-items-center">
                    <Col>
                        <div className="page-title-box d-flex align-items-center justify-content-between">
                            <h4 className="page-title mb-0 font-size-18">CA LÀM VIỆC</h4>
                        </div>
                    </Col>
                    <Col className="text-white d-flex flex-row justify-content-end">
                        <b className="h-auto">Ca làm việc </b>
                        <p className="mb-0"> / Chỉnh sửa</p>
                    </Col>
                </Row>
                <div className="card-container">
                    <h4 className="heading">Chỉnh sửa</h4>
                    <div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="id" className="label">Mã ca</Label>
                            <Input name="code" className="input-edit-info disabled" type="text" value={shiftDetail.code}
                                disabled required />
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="shiftName" className="label">Tên ca làm việc</Label>
                            <Input invalid={invalidForm.shiftName} name="shiftName" placeholder="Nhập tên ca làm việc"
                                className="input-edit-info" type="text" value={shiftDetail.shiftName}
                                onChange={handleChangeInput}
                                required />
                            {invalidForm.shiftName && <p className="message-err">
                                Chưa nhập tên ca làm việc
                            </p>}
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="startDate" className="label">Giờ bắt đầu</Label>
                            <Input
                                invalid={invalidForm.startDate}
                                type="time"
                                className="input-time"
                                name="startDate"
                                value={shiftDetail.startDate}
                                onChange={handleChangeInput}
                            />
                            {invalidForm.startDate && <p className="message-err">
                                Chưa chọn giờ bắt đầu
                            </p>}
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="endDate" className="label">Giờ kết thúc</Label>
                            <Input
                                invalid={invalidForm.endDate}
                                type="time"
                                className="input-time"
                                name="endDate"
                                value={shiftDetail.endDate}
                                onChange={handleChangeInput}
                            />
                            {invalidForm.endDate && <p className="message-err">
                                Chưa chọn giờ kết thúc
                            </p>}
                        </div>
                        <div>
                            <Label htmlFor="status" className="label">Trạng thái</Label>
                            <div className="d-flex flex-row align-items-center">
                                <div className="form-switch me-1">
                                    <input className="form-check-input" name="status" type="checkbox"
                                        role="switch" id="switchCheckChecked" checked={shiftDetail.status === "ACTIVE"}
                                        onChange={() => setShiftDetail({ ...shiftDetail, status: shiftDetail.status === "ACTIVE" ? "INACTIVE" : "ACTIVE" })} required />
                                </div>
                                {
                                    shiftDetail.status === "ACTIVE" ?
                                        (
                                            <span className="text-success">
                                                (Hoạt động)
                                            </span>
                                        ) : (
                                            <span className="text-danger">
                                                (Không hoạt động)
                                            </span>
                                        )
                                }
                            </div>
                            <div className="">
                                <Label htmlFor="colorCode" className="label">Màu</Label>
                                <Input name="colorCode" className="color-picker" type="color" value={shiftDetail.colorCode} onChange={handleChangeInput} />
                            </div>
                        </div>
                    </div>
                    <div className="mt-3 d-flex flex-row justify-content-end">
                        <div className="mt-3 d-flex flex-row justify-content-end">
                            <Button
                                color="success"
                                outline
                                className="me-2"
                                onClick={handleSubmit}
                            >
                                Lưu lại
                            </Button>
                            <Button
                                color="danger"
                                outline
                            >
                                <Link to="/schedule/list/work">
                                    Trở lại
                                </Link>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}